@import "assets/styles/imports";

.leftbar {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: $leftbar-width;
   top: $topbar-height;
   bottom: 0;
   position: fixed;
   background-color: white;
   transition: all 0.3s;
   &.hide {
      left: -$leftbar-width;
   }
   &.show {
      left: 0px;
   }
   .user-profile {
      background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),
         url($background-user-path) no-repeat;
      background-size: cover;
      img {
         display: block;
         padding: 11px 16px 16px 16px;
         width: 50px;
         visibility: hidden;
      }
      .username {
         color: white;
         background-color: #2f3d4abf;
         padding: 8px;
      }
   }

   .leftbar-menu {
      margin-top: 8px;
      padding: 4px 16px 16px 16px;
      ul.leftbar-menu-list {
         list-style: none;
         padding-left: 0;
         margin-bottom: 0;
         .leftbar-menu-title {
            margin-top: 24px;
            font-size: 0.775rem;
            padding: 5px 15px;
            width: 100%;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
         }
         .leftbar-menu-item {
            width: 100%;
            margin-bottom: 10px;
            a.leftbar-menu-link {
               color: inherit;
               color: rgba(0, 0, 0, 0.5);
               display: flex;
               padding: 12px;
               align-items: center;
               white-space: nowrap;
               text-decoration: none;
               gap: 1rem !important;
               transition: all 0.3s;
               &.active-link {
                  background: #fcae58;
                  color: #fff;
                  border-radius: 0.3rem;
                  font-weight: 500;
               }
               &:not(.active-link):hover {
                  background: rgba(0, 0, 0, 0.1);
                  border-radius: 0.3rem;
                  font-weight: 500;
               }
            }
         }
      }
   }
   .logout {
      cursor: pointer;
      transition: all 0.3s;
      font-size: 18px;
      text-align: center;
      padding: 15px 10px;
      background-color: rgba(255, 0, 0, 0.9);
      color: white;
      &:hover {
         background-color: rgba(255, 0, 0.06);
      }
   }
}
