@import 'assets/styles/imports';

body {
   .App {
      font-family: "Poppins",sans-serif;
      font-size: .9375rem;
      font-weight: 400;
      line-height: 1.5;
      color: #67757c;
      display: flex;
      min-height: 100vh;
      background-color: $background-color;
      overflow: hidden;
      .content-area {
         padding-top: $topbar-height;
         width: 100%;
         transition: all 0.3s;
         &.hide-leftbar {
            margin-left: 0;
         }
         &.show-leftbar {
            margin-left: $leftbar-width;
         }
         .content {
            padding: 40px;
            a.btn-back {
               font-size: 15px;
               color: #AAAAAA;
               display: flex;
               align-items: center;
               gap: 5px;
               text-decoration: inherit;
               width: fit-content;
            }
            header {
               display: flex;
               align-items: center;
               justify-content: space-between;
               margin-bottom: 35px;
               h1 {
                  color: $title-color;
                  font-size: 25px;
                  margin-bottom: 0;
               }
            }
         }
      }
   }
}