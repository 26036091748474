@import "assets/styles/imports";

.edit-food-page {
   .edit-food-form {
      padding: 20px;
      .ant-image {
         margin-bottom: 15px;
      }
      .buttons {
         display: flex;
         justify-content: center;
         gap: 8px;
         margin-top: 30px;
         flex-wrap: wrap;
      }
      .food-nutrients, .food-nutrients-groups {
         .food-nutrient-row, .food-nutrients-group-row {
            border-radius: 10px;
            background-color: #f7f7f7;
            padding: 24px 10px;
            margin-bottom: 15px;
            .ant-input-number-in-form-item {
               width: 100%;
            }
            .delete-action {
               display: flex;
               justify-content: flex-end;
               color: $red;
               margin-right: 10px;
               font-weight: bold;
               cursor: pointer;
               &:hover {
                  text-decoration: underline;
               }
            }
         }
      }
   }
}
.import-food-page {
   .import-food-form {
      padding: 20px;
      .ant-image {
         margin-bottom: 15px;
      }
      .buttons {
         display: flex;
         justify-content: center;
         gap: 8px;
         margin-top: 10px;
         flex-wrap: wrap;
      }
      input {
         outline: none!important;
         border: none!important;
         box-shadow: none!important;
      }
      .ant-form-item {
         margin-bottom: 0!important;
     }
     .ant-input {
      padding-left: 0;
      padding-right: 0;
     }
   }
}
