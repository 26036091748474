$topbar-height: 60px;
$leftbar-width: 260px;

$primary-color: #FCAE58;
$title-color: #2A2A2A;
$background-color: #f7f7f7;
$background-field-color: #F8F8F8;
$placeholder-field-color: #777777;

$red: rgb(191, 73, 73);

$background-user-path: "/assets/images/bg-user.jpg";
$placeholder-image: "/assets/images/placeholder-image.png";