@import 'assets/styles/imports';

#topbar {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: $topbar-height;
    width: 100%;
    background-color: $primary-color;
    color: white;
    z-index: 10;

    .logo {
        width: $leftbar-width;
        padding: 15px;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        a.logo-link {
            color: inherit;
            text-decoration: inherit;
            span {
                padding-left: 15px;
            }
        }
    }

    .toggle-leftbar-btn {
        display: flex;
        align-items: center;
    }

    .navbar {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        margin-right: auto;
    }

    .user-actions {
        display: flex;
        align-items: center;
        padding-right: 15px;
        .nav-btn .user-img {
            border-radius: 50%!important;
        }
    }

    .nav-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 0;
        color: white;
        background-color: transparent;
        border-radius: 50px;
        height: 45px;
        width: 45px;
        transition: all 0.3s;
        &:hover {
           background-color: rgba(0, 0, 0, 0.1);
        }
    }
}
