// @import '@src/modules/tender/common/assets/styles/imports';

$blue-1: #083258;
$blue-2: #035097;
$blue-3: #1b1d36;

$grey-1: #f8f8fc;
$grey-2: #9b9b9b;
$grey-3: #dfdfdf;
$grey-4: #ededed;

$red-1: #f5222d;

$yellow-1: #fffada;

.ant-btn.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 2px solid transparent;
  padding: 25px!important;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.5s;
  color: black;
  border: initial;
  font-size: 18px;
  border: 0;
  color: white;
  background-color: #37bd54;
  border: 1px solid #30a74a;
  &.small {
   padding: 20px 15px!important;
   font-size: 16px;
  }
  &:hover {
    box-shadow: 0 2.5px 0 rgba(0, 0, 0, 0.1);
    background-color: #30a74a;
    border: 1px solid #30a74a;
  }
  &:focus {
    box-shadow: 0 2.5px 0 rgba(0, 0, 0, 0.1);
    background-color: #30a74a;
    border: 1px solid #30a74a;
  }
  &.blue {
   background-color: #45669c;
   border: 1px solid #3d5a88;
   &:hover {
     box-shadow: 0 2.5px 0 rgba(0, 0, 0, 0.1);
     background-color: #3d5a88;
     border: 1px solid #3d5a88;
   }
   &:focus {
     box-shadow: 0 2.5px 0 rgba(0, 0, 0, 0.1);
     background-color: #3d5a88;
     border: 1px solid #3d5a88;
   }
  }
  &.grey {
   background-color: #6c757d;;
   border: 1px solid #5a6268;
   &:hover {
     box-shadow: 0 2.5px 0 rgba(0, 0, 0, 0.1);
     background-color: #5a6268;
     border: 1px solid #5a6268;
   }
   &:focus {
     box-shadow: 0 2.5px 0 rgba(0, 0, 0, 0.1);
     background-color: #5a6268;
     border: 1px solid #5a6268;
   }
  }
}
