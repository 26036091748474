.login-page {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   .left {
      width: 450px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .page-title {
         font-weight: bold;
         font-size: 30px;
         color: black;
         margin-bottom: 40px;
      }
      .login-form {
         width: 350px;
         label {
            font-size: 18px;
         }
         input {
            height: 45px;
         }
      }
   }
   .right {
      flex: 1;
      background-image: url('/assets/images/login/background.jpg');
      background-size: cover;
      background-position: center center;
   }
}