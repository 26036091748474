@import 'assets/styles/imports';

.foods-groups-page {
    .foods-groups {
        display: flex;
        flex-direction: column;
        .foods-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            padding: 10px 20px;
            transition: all 0.3s;
            cursor: pointer;
            .left {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                .icon {
                    margin-right: 10px;
                }
            }
            .actions {
                display: flex;
                align-items: center;
                transition: all 0.3s;;
                opacity: 0;
                .delete-action {
                    color: $red;
                    margin-right: 10px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &:hover {
                color: $red;
                .actions {
                    opacity: 1;
                }
            }
        }
    }
}